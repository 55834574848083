<template>
  <div class="">
    
    <HeaderVue heading="Vendors" class="mb-3">
      <template v-slot:buttons>
        <div class="d-flex align-items-between">
    <!-- <AddVendorModal></AddVendorModal> -->
          <AddCategory ref="addVendorModal" :categoryTitle="{singular:'vendor', plural:'vendor'}" @submit="saveVendors($event)" :isLoading="isLoadAddVendors" ></AddCategory>
          <button  v-if="getNavbarData.userType=='shopify'"
            class="btn px-3 py-2 ml-2 shopify-btn "
            @click="openShopifyVendorModal()">
            import from <i class="fab fa-shopify"></i>
          </button>
           <SyncBigCommerceVendors v-if="getNavbarData.userType == 'bc'" class="ml-2"></SyncBigCommerceVendors>
        </div>
      </template>
    </HeaderVue>

    <div class="card border-radius">
      <div class="card-body">
        <div class="">
          <FilterBox
            :search="true"
            v-model.trim="titleInput"
            placeholder="Search Vendors..."></FilterBox>
                        <div class="loader d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center" v-if="isLoading">
                <b-spinner small style="color: #4d1b7e"></b-spinner>
                <span class="ml-2">Loading Vendors</span>
              </div>
           
            </div>
          <div class="skeleton" v-if="isLoadingFirstTime">
            <b-skeleton-table
              class="py-3"
              :rows="5"
              :columns="4"
              :table-props="{
                bordered: true,
                striped: true,
              }"></b-skeleton-table>
          </div>
          <div class="data-table"  v-else-if="items.length>0">
            <b-table
              head-class="text-center"
              class="leadTable"
               :responsive="true"
              :stickyColumn="true"
              hover
              :fields="fields"
              :items="items">
               <template #head(checkbox)>
              <b-form-checkbox
                v-model="selectAllQuizellVendors"
                size="lg"
                @input="toggleQuizellVendors()"
                class="checkbox"></b-form-checkbox>
            </template>
               <template #cell(checkbox)="data">
              <b-form-checkbox
                v-model="selectedQuizellVendors"
                :value="data.item.id"
                size="lg"
                class="checkbox"></b-form-checkbox>
            </template>
            <template #cell(title)="data">
                <div class="d-flex align-items-center">
                 
                  <span class="text-hover-underline"  @click="$refs.editVendorModal.open({title:data.item.title,image:''})">{{ data.item.title }}</span>
                </div>
              </template>
                <template #cell(actions)="data">
    <button  class="btn" @click="()=>{
      editVendorId=data.item.id
      $refs.editVendorModal.open({title:data.item.title,image:''})
    }"><svg  viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="pencil square" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-pencil-square b-icon bi"><g ><path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"></path><path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"></path></g></svg></button>
     </template>

            </b-table>
             </div>
             <template v-else>

<div class="d-flex justify-content-center align-items-center" style="height: 50vh;" >

<EmptyTable >
<template #title>
No vendor found
</template>
<template #description>
Time to fill your shelves or refine your search. Remember, your results will appear right here.
</template>
</EmptyTable>
</div>

</template>
            <div class="">
              <QuizellPagination  :total-rows="totalRows" :currentPage="currentPage"
                  :per-page="perPage"    v-if="items.length" @update="updatePagination" @UpdatePerPage="updatePerPage"></QuizellPagination>
           
            </div>
          </div>
       
      </div>
    </div>

    <!-- Shopify Modal -->
    <ModalVue
      ref="Modal"
      id="copyShopifyVendors"
      title="Import from Shopify"
      size="lg">
      <div class="my-2" :class="!isLoadingSync?'d-none':'d-block'">
        <ProgressbarVue ref="progressbar"></ProgressbarVue>
       <span class="text-success text-center" style="font-size:12px;text-align:center"> Vendors syncing soon, continue tasks uninterrupted. </span>
      </div>
      
      <FilterBox
        :search="true"
        placeholder="Search Vendors..."
        @input="(e)=>{shopifyVendorsFilterText=e}"></FilterBox>

    
         <div class="loader px-2">
         <div class="d-flex py-2" :class="[isLoadingShopifyVendors?'justify-content-between':'justify-content-start']">
          <div
            class="d-flex align-items-center"
            v-if="isLoadingShopifyVendors">
            <b-spinner small style="color: #4d1b7e"></b-spinner>
            <span class="ml-2">Loading Vendors</span>
          </div>
          <div class="" v-if="shopifySelectedVendors.length">
            Selected Vendors : {{ shopifySelectedVendors.length }}
          </div>
          </div>
        </div>
      <b-skeleton-table v-if="isLoadingShopifyVendorFirstTime"
  :rows="5"
  :columns="3"
  :table-props="{ bordered: true, striped: true }"
></b-skeleton-table >
      <div class="data-table" v-else-if="paginatedVendors.length>0">
     
        <div class="" style="max-height: 55vh; min-height:55vh; overflow: auto">
          <b-table
            head-class="text-center"
            class="leadTable"
            :stickyColumn="true"
            hover
            :fields="shopifyVendorsFields"
            :items="paginatedVendors">
            <template #head(checkbox)>
              <b-form-checkbox
                size="lg"
                :disabled="isLoadingShopifyVendors || isLoadingSelectedShopifyVendors || isLoadingSync"
                v-model="shopifySelectAllCheck"
                @change="shopifyVendorSelectAllToggle($event)"
                class="checkbox selectAll"></b-form-checkbox>
            </template>
            <template #cell(checkbox)="data">
              <b-form-checkbox
                :value="data.item"
                v-model="shopifySelectedVendors"
                :disabled="isLoadingShopifyVendors || isLoadingSelectedShopifyVendors || isLoadingSync"
                size="lg"
                class="checkbox"></b-form-checkbox>
            </template>
           
          </b-table>
        </div>
      </div>
        
      <template v-else>
        <div class="d-flex justify-content-center align-items-center">
          <EmptyTable >
<template #title>
No vendor found
</template>
<template #description>
Time to fill your shelves or refine your search. Remember, your results will appear right here.
</template>
</EmptyTable>
        </div>
      </template>

      <div class="d-flex justify-content-between">
        <b-pagination
        class="customPagination"
          v-model="queryObject.currentPage"
          :total-rows="pageCount"
          :per-page="queryObject.perPage"
          @input="(e)=>{queryObject.currentPage=e}"></b-pagination>
          <div class="">
          <div
            class="d-flex py-2 py-lg-0 w-100 flex-row  align-items-center justify-content-lg-end" >
           
            <BorderButton
              :custom="true"
              @click="syncSelected()" :disabled="isLoadingSync || isLoadingShopifyVendors || isLoadingSelectedShopifyVendors " :isLoading="isLoadingSelectedShopifyVendors"   v-if="shopifySelectedVendors.length"
              class="secondary-color-button mr-3"
             
              >Sync Selected</BorderButton
            >
            <button v-else :disabled="isLoadingSync || isLoadingShopifyVendors || isLoadingSelectedShopifyVendors " class="btn d-flex px-3 justify-content-center align-items-center py-2 shopify-btn" @click="syncAllVendors()">
              <div v-if="isLoadingSync" class="d-flex align-items-center">
                  <b-spinner small ></b-spinner>
                  <span class="ml-1">Syncing...</span>
              </div>
             
              <span v-else>Sync all vendors</span>
              
            </button>
          </div>
        </div>
      </div>
    </ModalVue>
    <DeleteFooterModal ref="deleteModal" :isLoading="isLoadingDeleteVendor" @click="deleteSelectedVendors()" :itemLength="selectedQuizellVendors.length" name="vendors"
    ></DeleteFooterModal>
    <AddCategory ref="editVendorModal" :editMode="true" :categoryTitle="{singular:'vendor', plural:'vendor'}" @submit="saveVendors($event,true)" :isLoading="isLoadAddVendors" ></AddCategory>

  </div>
</template>
<script>
import SyncBigCommerceVendors from "./modal/SyncBigCommerceVendors.vue";
import { mapGetters } from "vuex";
import { debounce } from "lodash";

import BorderButton from "../Layout/BorderButton.vue";
import FilterBox from "../Layout/FilterBox.vue";
import HeaderVue from "../Layout/Header.vue";
import ModalVue from "../Layout/Modal.vue";
import ProgressbarVue from "../Layout/Progressbar.vue";
import axios from "axios";
// import AddVendorModal from './modal/AddVendor.vue';
import DeleteFooterModal from '../Layout/DeleteFooterModal.vue';
import AddCategory from "./modal/AddCategory.vue";
import QuizellPagination from "../Layout/QuizellPagination.vue";
import EmptyTable from "../Layout/EmptyTable.vue";
export default {
  components: {
    QuizellPagination,
    EmptyTable,
    FilterBox,
    BorderButton,
    HeaderVue,
    SyncBigCommerceVendors,
    // ShopifyPagination,
    ModalVue,
   
    ProgressbarVue,
    // AddVendorModal,
    DeleteFooterModal,
    AddCategory
  },
  data() {
     
    return {
      titleInput:'',
      editVendorId:null,
       isLoadAddVendors:false,
      selectAllQuizellVendors:false,
      selectedQuizellVendors:[],
      searchVendorText:'',
        currentPage: 1,
        perPage: 10,
      totalRows: 10,
      pageInfo: {
        hasNextPage: false,
        hasPreviousPage: false,
        endCursor: "",
        startCursor: "",
      },
      isLoadingShopifyVendors: false,
      isLoadingShopifyVendorFirstTime:false,
      shopifySelectAllCheck: false,
      shopifySelectedVendors: [],
      fields: [
        {
          key: "checkbox",
          label: "",
          tdClass: "data-Table align-middle cursor-pointer",
          thClass:'bg-table-heading',
        },
         
        {
          key: "title",
          label: "Title",
          tdClass: "data-Table align-middle cursor-pointer",
          thClass:'bg-table-heading',
        },
        
        {
          key: "actions",
          label: "Actions",
          tdClass: "align-middle data-Table d-flex justify-content-end cursor-pointer",
          thClass: "bg-table-heading  text-right",
        },
      ],
      shopifyVendors: [],
      shopifyVendorsFields: [
        {
          key: "checkbox",
          label: "",
          tdClass: "data-Table align-middle cursor-pointer",
           thClass:'bg-table-heading',
        },
        {
          key: "title",
          label: "Vendor",
          tdClass: "data-Table align-middle cursor-pointer",
          thClass:'bg-table-heading',
        },

       
      ],
      items: [],
      shopifyVendorsFilterText:'',
      isLoadingFirstTime: true,
      isLoadingSelectedShopifyVendors:false,
      isLoadingDeleteVendor:false,
      isLoadingSync:false,
      isLoading: false,
      queryObject: {
        currentPage: 1,
      perPage: 50,
      totalRows: 10,
      },
    };
  },
  watch: {
     items:{
      handler(newValue){
        if(newValue.every(vendor=>this.selectedQuizellVendors.includes(vendor.id))&&(!newValue))
        this.selectAllQuizellVendors=true;
        else
         this.selectAllQuizellVendors=false;
      },
      deep:true
   },
   titleInput(){
        this.handleVendorSearchDebounced()
      },
    shopifySelectedVendors: {
      handler(newVal) {
        if (newVal.length > 0 && this.paginatedVendors.length > 0)
          this.shopifySelectAllCheck = this.paginatedVendors.every(
            (vendor) => newVal.includes(vendor)
          );
        else this.shopifySelectAllCheck = false;
        // newVal.length === this.shopifyVendors.length;
      },
      deep: true,
    },
    paginatedVendors: {
      handler(newVal) {
        const vendors = newVal;
        if (
          vendors.length > 0 &&
          this.shopifySelectedVendors.length > 0
        )
          this.shopifySelectAllCheck = vendors.every((vendor) =>
            this.shopifySelectedVendors.includes(vendor)
          );
        else this.shopifySelectAllCheck = false;
        // this.shopifySelectAllCheck = newVal.length === this.shopifySelectedVendors.length;
      },
      deep: true,
    },
    '$route.query.title'(newValue) {
      this.currentPage=1;
      this.titleInput=newValue;
      this.searchVendorText=newValue
      
    },
    '$route.query.page'(newValue) {
      this.currentPage= newValue ? parseInt(newValue) : 1;
    },
    '$route.query.perPage'(newValue) {
      this.perPage= newValue ? parseInt(newValue) : 10;
    },
  },
  computed: {
    
    ...mapGetters(["getNavbarData"]),
    shopifyAllVendorsIds() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.shopifyVendors.map((vendor) => vendor.id);
    },
     pageCount() {
     
      return this.filteredVendors.length
    },
  paginatedVendors() {
  if (!this.filteredVendors || !Array.isArray(this.filteredVendors)) {
    return [];
  }
  const currentPage = parseInt(this.queryObject.currentPage);
  const perPage = parseInt(this.queryObject.perPage);
  const startIndex = (currentPage - 1) * perPage;
  const endIndex = startIndex + perPage;
  return this.filteredVendors.slice(startIndex, endIndex);
},
  filteredVendors(){
     const vendors=this.shopifyVendors.filter(vendor=>{return vendor.title.toLowerCase().includes(this.shopifyVendorsFilterText.toLowerCase())|| this.shopifyVendorsFilterText==''})
   
     return vendors
},
  },

  methods: {
    updatePerPage(e){
      this.currentPage=1;
      this.perPage=e;
      this.applyFilters();
    },
    updatePagination(e){
      this.currentPage=e;
      this.applyFilters();
    },
    applyFilters() {
      this.searchVendorText=''
      const queryParams = {};
      if (this.titleInput) {

        queryParams.title = this.titleInput;
         this.searchVendorText = this.titleInput;
      }
      queryParams.perPage=this.perPage;
      queryParams.page=this.currentPage;
      this.$router.replace({ query: queryParams });
      this.getVendors()
    },
       async saveVendors(data,editMode=false){
          let path = editMode?'vendor/update':'vendor/save'
          if(editMode) data.vendor_id=this.editVendorId
      this.isLoadAddVendors=true
     await axios.post(path,data).then(()=>{
        this.$toasted.show("Vendors saved successfully", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          if(editMode)
           this.$refs.editVendorModal.close();
          else
          this.$refs.addVendorModal.close();
          this.getVendors();
      }).catch((error)=>{
        let {title} = error.response.data.errors
        if(title){
          this.$toasted.show(title, {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        } else{
          this.$toasted.show("Error occurred.", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
       
      })
      this.isLoadAddVendors=false
    },
    toggleQuizellVendors(){
        if(this.selectAllQuizellVendors)
       this.selectedQuizellVendors =  [...new Set([...this.selectedQuizellVendors, ...this.items.map(vendor=>vendor.id)])];
       else 
        this.selectedQuizellVendors = []
    },
    async deleteSelectedVendors(){
      this.isLoadingDeleteVendor=true
 await axios
        .post("/vendor/delete",{vendor_ids: this.selectedQuizellVendors})
        .then(() => {
          this.$refs.deleteModal.close();
          this.currentPage>1&&this.selectedQuizellVendors==this.items?this.updatePagination(this.currentPage):this.getVendors()
          this.selectedQuizellVendors=[];
          this.$toasted.show("Vendors deleted successfully", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
        })
        .catch((e) => {
           this.$toasted.show("Error occurred while deleting vendors", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
          console.log(e);
        });
        this.isLoadingDeleteVendor=false
    },
     PaginationData() {
      this.getVendors();
    },
    openShopifyVendorModal(){
     
       this.isLoadingShopifyVendorFirstTime=true;
       this.$refs.Modal.open();
       this.getVendorsFromShopify()
    },
    async syncSelected(){
      this.isLoadingSelectedShopifyVendors=true
 await axios
        .post("vendor/save/selected",{vendors: this.shopifySelectedVendors})
        .then(() => {
          this.$toasted.show("Vendors sync successfully", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
        })
        .catch((e) => {
           this.$toasted.show("Error occurred while syncing vendors", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
          console.log(e);
        });
          this.isLoadingSelectedShopifyVendors=false
    },
  
    shopifyVendorSelectAllToggle() {
      if(this.shopifySelectAllCheck)
       this.shopifySelectedVendors =  [...new Set([...this.shopifySelectedVendors, ...this.paginatedVendors])];
       else  this.shopifySelectedVendors = []
      
    },
    async syncAllVendors() {
      this.isLoadingSync=true;
      this.progress = 100;
          setTimeout(() => {
          this.$refs.progressbar.start();
          }, 1000);
     
      await axios
        .post("/vendor/sync/store")
        .then(() => {
          
           this.$refs.progressbar.stop();
          this.$toasted.show("Vendors sync successfully", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
         this.$refs.Modal.close();
            this.getVendors();
        })
        .catch((e) => {
           this.$refs.progressbar.error();
          console.log(e);
            this.$toasted.show("Error occurred while syncing vendors. ", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
        });
       
        
         this.progress = 100;
          setTimeout(() => {
         this.isLoadingSync=false;
          }, 2000);
        
    },
    async getVendorsFromShopify() {
      this.isLoadingShopifyVendors = true;
      
      const data = {
        // searchQuery: `GetVendors($searchQuery: String!, $first: Int!, $after: String) { vendors(first: $first, query: $searchQuery, after: $after) { edges { node { id title productsCount } } pageInfo { endCursor startCursor hasNextPage hasPreviousPage } } }`,
        variables: this.queryObject,
      };
      await axios
        .post("/vendor/search", data)
        .then((response) => {
          if (response.data.status == "success") {
          
            this.shopifyVendors = response.data.data.map((vendor)=>{return{title:vendor.node}})
          }
          else{
             this.$toasted.show("Error occurred getting vendors. ", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
          }
        })
        .catch((e) => {
          console.log(e);
        });
      this.isLoadingShopifyVendors = false;
      this.isLoadingShopifyVendorFirstTime=false;
    },
    async getVendors() {
      
      this.isLoading = true;
      await axios
        .post('vendor/list',{title:this.searchVendorText, per_page:this.perPage, page:this.currentPage})
        .then((response) => {
          this.items=response.data.data.data;
           this.totalRows = response.data.data.total;
          this.perPage= response.data.data.per_page
          
        })
        .catch((e) => {
          console.log(e);
        });
      this.isLoadingFirstTime = false;
      this.isLoading = false;
     
    },
   
    handleSearchDebounced: debounce(function () {
      this.getVendorsFromShopify();
    }, 500), // 500ms debounce time, adjust as needed
     handleVendorSearchDebounced: debounce(function () {
      this.applyFilters();
    }, 500),
  },
  mounted(){
   this.getVendors()
  },
  created(){
    const query = this.$route.query;
    this.titleInput = this.searchVendorText = query.title || '';
    this.currentPage= parseInt(query.page) || 1;
    this.perPage= parseInt(query.perPage) || 10;
    console.log(this.perPage)
  }
};
</script>
<style scoped>
.shopify-btn:active {
  background-color: #002e25;
  color: #ffffff;
  border-color: #002e25;
}
.shopify-btn {
  appearance: none;
  cursor: pointer;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #008060;
  color: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;

  font-size: 14px;

  transition: 150ms ease;
  transition-property: background-color, border-color;
  display: inline-block;

  border-radius: 6px;
}
.checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--secondary-color);
  background-color: var(--secondary-color) !important;
}
.checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem var(--secondary-color);
}
.leadTable >>> thead {
  border-radius: 6px;
  color: black;
  background: rgba(179, 175, 182, 0.07);
  border: none;
}
.leadTable >>> thead tr {
  border-radius: 10px;
}
.leadTable >>> thead th {
  border: none;
  vertical-align: middle !important;
}
.loader {
  min-height: 40px;
}
.product-image {
    height: 50px;
    width: 50px;
    /* border: 4px solid rgb(255, 255, 255); */
    border-radius: 50%;
    -o-object-fit: cover;
    object-fit: cover;
    background-size: cover;
    padding: 0.25rem;
    background-color: #fff;
}

</style>
